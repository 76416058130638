<template>
  <Popup ref="popupRef" :title="`${isEdit ? '编辑':'添加'}配置`" width="35%" @confirm="handleConfirm" @close="resetForm">
    <el-form :model="form" label-position="top" :rules="rules">
      <el-form-item label="配置名称">
        <el-input v-model="form.confName" placeholder="请输入配置名称"></el-input>
      </el-form-item>
      <el-form-item label="目标APP" prop="productIds">
        <el-select v-model="form.productIds" placeholder="请选择目标App" multiple style="width:100%;">
          <el-option
            v-for="item in appList"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select v-model="form.chs" placeholder="请选择渠道" multiple style="width:100%;">
          <el-option
            v-for="item in channelList"
            :key="item.key"
            :label="item.value"
            :value="item.key">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="最高版本号" prop="highVersion">
        <el-input type="text" v-model="form.highVersion" placeholder="请输入最高版本号"></el-input>
      </el-form-item>
      <el-form-item label="最低版本号" prop="lowVersion">
        <el-input type="text" v-model="form.lowVersion" placeholder="请输入最低版本号"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import { dictListApi } from '@/api/opera.js'
import { resProductConfManageApi } from '@/api/resConf.js'
export default {
  components: {
    Popup
  },
  data() {
    var checkVersion = (rule, value, callback) => {
      if (value.trim()) {
        const regExp = /^[\d-.]*$/
        if(!regExp.test(value)) callback(new Error('请输入正确格式的版本号'))
      } else {
        callback()
      }
    };
    return {
      confId: '',
      isEdit: false,
      resType: '',
      appList: [],
      channelList: [],
      form: {
        productIds: [],
        chs: [],
        confName: '',
        highVersion: '',
        lowVersion: ''
      },
      rules: {
        productIds: [
          { required: true, message: '请选择目标App', trigger: 'blur' }
        ],
        highVersion: [
          { validator: checkVersion, trigger: 'blur' }
        ],
        lowVersion: [
          { validator: checkVersion, trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.getAppList()
    this.getChannelList()
  },
  methods: {
    open(option) {
      console.log('option', option)
      this.isEdit = option.isEdit || false
      this.resType = option.resType
      if(this.isEdit && option.detail) {
        this.confId = option.detail.confId || ''
        this.form.chs = option.detail.chs
        this.form.productIds = option.detail.productIds
        this.form.confName = option.detail.configName
        this.form.highVersion = option.detail.highVersion
        this.form.lowVersion = option.detail.lowVersion
        this.$refs.popupRef.open()
      } else {
        this.$refs.popupRef.open()
      }
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetForm() {
      this.form = {
        productIds: [],
        chs: [],
        confName: '',
        highVersion: '',
        lowVersion: ''
      }
    },
    getAppList() {
      dictListApi({
        code: 'productId',
        pageSize: 100,
        startPage: 0
      }).then(res => {
        console.log('应用列表', res)
         this.appList = res.data?.dataList[0]?.dictItem || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    getChannelList() {
      dictListApi({
        code: 'channel',
        pageSize: 20,
        startPage: 0
      }).then(res => {
        console.log('渠道列表', res)
        this.channelList = res.data?.dataList[0]?.dictItem || []
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    handleConfirm() {
      if(!this.form.productIds?.length) return this.$tips({message: '请选择目标App', type: 'warning'})
      const formData = {
        resType: this.resType,
        act: this.isEdit ? 2 : 1,
        productIds: this.form.productIds,
        chs: this.form.chs,
        confName: this.form.confName,
        highVersion: this.form.highVersion,
        lowVersion: this.form.lowVersion,
        grayConfig: this.$store.state.isResourceGray
      }
      if(this.isEdit) {
        Object.assign(formData, {confId: this.confId})
      }
      console.log('formData', formData)
      resProductConfManageApi(formData).then(res => {
        console.log('添加配置',res)
        this.$tips({message: res.msg, type: 'success'})
        this.close()
        this.$emit('update')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>